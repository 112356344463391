



































































































































































import { computed, defineComponent, onBeforeMount } from "@vue/composition-api";
import {
	mapActions as mapPiniaActions,
	mapState as mapPiniaState,
	mapWritableState as mapPiniaWritableState,
} from "pinia";
import { getFullName } from "@/lib/getFullName";
import { useCurrentGym } from "@/lib/hooks/useCurrentGym";
import { useCoaches } from "@/lib/query/hooks/useCoaches";
import { useGyms } from "@/lib/query/hooks/useGyms";
import { useLocationStore } from "@/pinia/location";
import { useMemberStore } from "@/pinia/member";
import { getIconName } from "@/utils/images";

export default defineComponent({
	setup() {
		const { legacy_locationSlug: gymSlug } = useCurrentGym();
		const memberStore = useMemberStore();

		const { data: gyms } = useGyms();

		const { data: coaches } = useCoaches(
			computed(() => ({
				gymId: gyms.value?.find((gym) => gym.slug === memberStore.filters.vestiging)?.id,
			})),
		);

		onBeforeMount(() => memberStore.setFilters({ vestiging: gymSlug.value ?? "all" }));

		return {
			getIconName,
			getFullName,
			gyms,
			coaches,
		};
	},
	data() {
		return {
			loading: false,
		};
	},
	computed: {
		...mapPiniaState(useMemberStore, {
			members: "members",
			membersLoading: ({ state: { loading } }) => loading,
			membersDirty: ({ state: { dirty } }) => dirty,
		}),
		...mapPiniaWritableState(useMemberStore, {
			filters: "filters",
			membersState: "state",
		}),
		...mapPiniaState(useLocationStore, {
			location: "location",
		}),
	},
	watch: {
		filters: {
			async handler() {
				if (this.membersDirty === undefined) {
					this.setMembersState({
						dirty: false,
					});
				} else {
					this.setMembersState({
						dirty: true,
						endReached: false,
						page: 1,
					});
					this.getMembersDebounced();
				}
			},
			deep: true,
		},
	},
	async created() {
		this.loading = true;
		this.initMembers();
		this.resetMembersState();
		await this.getMembers();
		this.setInfiniteScroll();
		this.loading = false;
	},
	async destroyed() {
		this.resetFilters();
		this.removeInfiniteScroll();
	},
	methods: {
		...mapPiniaActions(useMemberStore, {
			initMembers: "init",
			getMembers: "get",
			getMembersDebounced: "debouncedGet",
			setFilters: "setFilters",
			resetFilters: "resetFilters",
			setMembersState: "setState",
			resetMembersState: "resetState",
		}),
		setInfiniteScroll() {
			window.onscroll = async () => {
				const OFFSET = 20;
				const bottomOfWindow =
					document.documentElement.scrollTop + window.innerHeight >=
					document.documentElement.offsetHeight - OFFSET;
				if (bottomOfWindow) await this.getMembers();
			};
		},
		removeInfiniteScroll() {
			window.onscroll = null;
		},
	},
});
